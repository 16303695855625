import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from './hoc/Layout/Layout';

import withErrorHandler from './hoc/withErrorHandler/withErrorHandler';

import { getApiVersion } from './shared/store/actions';

import axios from './axios/axios-api';

import Webhooks from './pages/Webhooks/Webhooks';

class App extends Component {
  componentDidMount() {
    this.props.getApiVersion();
  }

  render() {
    let content = <Layout></Layout>;

    if (this.props.user) {
      content = (
        <Layout user={this.props.user} isLoadingUser={this.props.isLoadingUser} apiVersion={this.props.apiVersion}>
          <Switch>
            <Route path="/webhooks" component={Webhooks} />
            <Route path="/" component={Webhooks} />
          </Switch>
        </Layout>
      );
    }

    return content;
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser,
    apiVersion: state.toolbar.apiVersion
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getApiVersion: () => dispatch(getApiVersion())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(App, axios));