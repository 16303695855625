import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import ShortId from 'shortid';

import { Table, Button, Icon, Input, Header } from 'semantic-ui-react';

import Spinner from '../Spinner/Spinner';
import Paginator from '../Paginator/Paginator';

import styles from './ResourceViewer.module.scss';

let timeout = null;

export default class ResourceViewer extends Component {
  state = this.getInitialState();

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data && this.props.data && this.props.data.data) {
      this.setState({ data: this.props.data.data });
    }

    if (prevProps.showNewItemRow !== this.props.showNewItemRow) {
      this.setState({ newRow: {} });
    }
  }

  getInitialState() {
    return {
      newRow: {},
      data: this.props.data.entities || [],
      exportFormat: 'CSV',
      showModal: false,
      pageSize: 10,
      filter: {
        filters: []
      }
    };
  }

  getNewRow = () => {
    let newRow = {};
    this.props.columns.forEach((x) => newRow[x.id] = "");
    return newRow;
  }

  renderEditColumn = (col, item) => {
    let colId = col.id;

    if (col.type === 'number') {
      return <Table.Cell key={col[this.props.keyColumnName]}>
        <Input
          style={{ width: '100%' }}
          type="number"
          value={item[colId]}
          onChange={(e) => {
            item[colId] = e.target.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    } else {
      return <Table.Cell key={col[this.props.keyColumnName]}>
        <Input
          style={{ width: '100%' }}
          type='text'
          value={item[colId]}
          onChange={(e) => {
            item[colId] = e.target.value;
            this.forceUpdate();
          }}
        />
      </Table.Cell>;
    }
  };

  renderColumn = (col, item) => {
    if (col.isHidden) {
      return null;
    }

    if (item.isEditMode && this.props.editMode) {
      return this.renderEditColumn(col, item);
    } else {
      return <Table.Cell key={col.id}>{col.accessor ? col.accessor(item) : item[col.id]}</Table.Cell>;
    }
  };

  tableLoader = () => <Spinner loaderSize='medium' />;

  renderNewItemRow = () => {
    let columns = this.props.columns.map((col) => {
      if (col.isHidden) {
        return null;
      }

      if (col.isReadOnly) {
        return <Table.Cell key={col[this.props.keyColumnName]}></Table.Cell>;
      }

      let newRow = this.state.newRow;

      return <Table.Cell key={col[this.props.keyColumnName]}>
        <Input type="text"
          style={{ width: '100%' }}
          onChange={(e) => {
            if (Object.keys(newRow).length === 0)
              newRow = this.getNewRow();

            newRow[col.id] = e.target.value;
            this.setState({ newRow: newRow });
          }} />
      </Table.Cell>;
    });

    if (this.props.editMode)
      columns.push(<Table.Cell key="action">
        <Icon name='check' onClick={(e) => {
          let newItem = { ...this.state.newRow };

          this.props.columns.forEach(col => {
            if (col.isHidden && !newItem[col.id]) {
              delete newItem[col.id];
            }
          });

          this.props.handleSave(newItem);
        }} style={{ cursor: 'pointer' }} />
        <Icon name='close' onClick={(e) => {
          this.props.handleCancelAdd();
          this.setState({ newRow: {} })
        }} style={{ cursor: 'pointer' }} />
      </Table.Cell>);

    if (this.props.selectionMode)
      columns.unshift(<Table.Cell key="selectionCol">
        <div>
          <Input type="checkbox" value={this.state.newRow.checked} />
        </div>
      </Table.Cell>);

    return <Table.Row>{columns}</Table.Row>;
  };

  renderRow = (item, i) => {
    let rows = [];

    let itemRow = <Table.Row key={item[this.props.keyColumnName] ? item[this.props.keyColumnName] : ShortId.generate()}>
      {this.props.selectionMode && (
        <Table.Cell key={ShortId.generate()}>
          <div>
            <Input onChange={() => this.props.onItemCheck(item)} type="checkbox" checked={item.checked} />
          </div>
        </Table.Cell>
      )}

      {this.props.columns.map((col) => this.renderColumn(col, item))}

      {this.props.editMode
        ? (item.isEditMode
          ? <Table.Cell width='1'>
            <Icon name='check'
              onClick={(e) => {
                item.isEditMode = false;
                this.forceUpdate();
                let { isEditMode, ...itemModel } = item;
                this.props.handleSave(itemModel);
              }} />
            <Icon name='close'
              onClick={(e) => {
                item.isEditMode = false;
                this.forceUpdate();
              }}
              style={{ cursor: 'pointer' }} />
          </Table.Cell>
          : <Table.Cell width='1'>
            <Icon name='edit'
              onClick={this.props.handleEditRow
                ? () => this.props.handleEditRow(item)
                : () => {
                  item.isEditMode = true;
                  this.forceUpdate();
                }}
              style={{ cursor: 'pointer' }} />
            <Icon name='close'
              onClick={this.props.handleRemoveRow ? (e) => this.props.handleRemoveRow(e, item) : (e) => this.props.handleRemove(item[this.props.idColumnName])}
              style={{ cursor: 'pointer' }} />
          </Table.Cell>)
        : null}
    </Table.Row>;

    rows.push(itemRow);

    // if (item.expanded && this.props.renderExpanded) {
    //   let expandedRow = (<tr key={(item[this.props.idColumnName] || i) + '_expanded'}>
    //     <td colSpan="100%">
    //       {this.props.renderExpanded(item)}
    //     </td>
    //   </tr>);
    //   rows.push(expandedRow);
    // }

    return rows;
  }

  componentWillUnmount() {
    clearTimeout(timeout);
  }

  getFilterValue = (fieldName) => {
    if (this.props.filter && this.props.filter.filters) {
      const filterItem = this.props.filter.filters.find(item => item.property === fieldName);

      if (filterItem) {
        return filterItem.value;
      }
    }

    return '';
  }

  render() {
    let table = (
      <div className={styles.Container}>
        <div className={styles.TableHeaderActions}>
          <span className={styles.TableHeader}>
            <Header as='h3' className={styles.headerText}>{this.props.header}</Header>
            <Icon name='refresh' onClick={this.props.refreshTable} className={styles.refreshBtn} />
          </span>
          {this.props.actions
            ? <Button className={styles.TableActionsButton} icon size='small' floated='right' labelPosition='left'
              as={Link} to={this.props.createResourceLink} exact="true">
              <Icon name='add' />
              Create
            </Button>
            : null}
          {this.props.editMode
            ? <Button type='button'
              className={styles.TableActionsButton}
              icon size='small' floated='right'
              labelPosition='left'
              onClick={this.props.handleAddNewRow} >
              <Icon name='add' />
              Add
            </Button>
            : null}
        </div>

        <Table celled selectable sortable className={styles.Table} fixed={this.props.fixed}>
          <Table.Header>
            {this.props.actions
              ? <Table.Row className={styles.FilterRow}>
                {this.props.columns.map(column => {
                  if (column.isHidden)
                    return null;

                  return (
                    <Table.HeaderCell key={column[this.props.keyColumnName]} width={column.width}>
                      {column.search
                        ? <Input
                          className={styles.SearchInput}
                          placeholder='Search...'
                          value={this.getFilterValue(column.id)}
                          onChange={(event) => this.props.filterGrid(column.id, event.target.value)} />
                        : null}
                    </Table.HeaderCell>)
                })}
                {this.props.editMode
                  ? <Table.HeaderCell width='1'></Table.HeaderCell>
                  : null}
              </Table.Row>

              : null}

            <Table.Row className={this.props.actions ? styles.HeaderRow : styles.HeaderRowWithoutActions}>
              {this.props.columns.map(column => {
                if (column.isHidden) {
                  return null;
                }

                return (
                  <Table.HeaderCell
                    key={ShortId.generate()}
                    width={column.width}
                    sorted={this.props.sortField === column.id ? (this.props.dir === 'asc' ? 'ascending' : 'descending') : null}
                    onClick={() => this.props.sort(column.id, this.props.dir === 'asc' ? 'desc' : 'asc')}>
                    {column.header}
                  </Table.HeaderCell>
                )
              })}
              {this.props.editMode
                ? <Table.HeaderCell width='1'></Table.HeaderCell>
                : null}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {this.props.loading
              ? <Table.Row>
                <Table.Cell style={{ position: 'relative' }} colSpan={this.props.columns.length}>
                  {this.tableLoader()}
                </Table.Cell>
              </Table.Row>
              : null}

            {this.props.showNewItemRow && this.renderNewItemRow()}

            {!this.props.loading && this.state.data && this.state.data.length
              ? this.state.data.map((item, i) => this.renderRow(item, i))
              : !this.props.loading
                ? <Table.Row>
                  <Table.Cell colSpan={this.props.columns.length} textAlign='center'>
                    <p className={styles.noData}>No data to display</p>
                  </Table.Cell>
                </Table.Row>
                : null}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={this.props.columns.length} className={styles.footerRow}>
                {!this.props.loading && this.state.data && this.state.data.length
                  ? <Paginator
                    floated='right'
                    size="small"
                    filteredCount={this.props.data.filteredCount}
                    page={this.props.page}
                    pageSize={this.props.pageSize}
                    pageSizeChanged={this.props.pageSizeChanged}
                    pageChanged={this.props.pageChanged} />
                  : null}
              </Table.HeaderCell>
              {this.props.editMode
                ? <Table.HeaderCell></Table.HeaderCell>
                : null}
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    );

    return table;
  }
}