import * as actionTypes from '../constants/actionTypes';
import { updateObject } from '../../../../shared/utils/utility';

const initialState = {
  webhooksLoading: false,
  webhooks: []
}

const fetchWebhooksStart = (state, action) => updateObject(state, { webhooksLoading: true });
const fetchWebhooksSuccess = (state, action) => {
  return updateObject(state, {
    webhooksLoading: false,
    webhooks: action.webhooks
  });
}
const fetchWebhooksError = (state, action) => updateObject(state, { webhooksLoading: false });

const saveWebhookStart = (state, action) => updateObject(state, { webhooksLoading: true });
const saveWebhookSuccess = (state, action) => updateObject(state, { webhooksLoading: false });
const saveWebhookError = (state, action) => updateObject(state, { webhooksLoading: false });

const deleteWebhookStart = (state, action) => updateObject(state, { webhooksLoading: true });
const deleteWebhookSuccess = (state, action) => updateObject(state, { webhooksLoading: false });
const deleteWebhookError = (state, action) => updateObject(state, { webhooksLoading: false });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_WEBHOOKS_START: return fetchWebhooksStart(state, action);
    case actionTypes.FETCH_WEBHOOKS_SUCCESS: return fetchWebhooksSuccess(state, action);
    case actionTypes.FETCH_WEBHOOKS_ERROR: return fetchWebhooksError(state, action);

    case actionTypes.SAVE_WEBHOOKS_START: return saveWebhookStart(state, action);
    case actionTypes.SAVE_WEBHOOKS_SUCCESS: return saveWebhookSuccess(state, action);
    case actionTypes.SAVE_WEBHOOKS_ERROR: return saveWebhookError(state, action);

    case actionTypes.DELETE_WEBHOOKS_START: return deleteWebhookStart(state, action);
    case actionTypes.DELETE_WEBHOOKS_SUCCESS: return deleteWebhookSuccess(state, action);
    case actionTypes.DELETE_WEBHOOKS_ERROR: return deleteWebhookError(state, action);

    default: return state;
  }
}

export default reducer;