import React from 'react';

import { Dimmer, Loader } from 'semantic-ui-react';

import styles from './Spinner.module.scss';

const Spinner = ({
  fullpage,
  loaderSize
}) => {
  return <div className={fullpage ? styles.FullpageLoaderBg : styles.LoaderBg}>
    <Dimmer inverted active>
      <Loader size={loaderSize ? loaderSize : 'massive'} />
    </Dimmer>
  </div>
};

export default Spinner;