import { WebStorageStateStore } from 'oidc-client';

export const API_URL = (() => {
  if (process.env.REACT_APP_ENV === 'local') {
    return 'http://localhost:5000/';
  }

  if (process.env.REACT_APP_ENV === 'dev') {
    return 'https://dev-eldconsolidator.loadrpm.com/';
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return 'https://eldconsolidator.loadrpm.com/';
  }
})();

export const SPELLBAR_CONFIG_ENDPOINT_URL = (() => {
  if (process.env.REACT_APP_ENV === 'local') {
    return 'https://authmanagement.loadrpm.com/api/userspellbar';
  }

  if (process.env.REACT_APP_ENV === 'dev') {
    return 'https://authmanagement.loadrpm.com/api/userspellbar';
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return 'https://authmanagement.loadrpm.com/api/userspellbar';
  }
})();

const AUTH_CLIENT_ID = (() => {
  if (process.env.REACT_APP_ENV === 'local') {
    return 'ELDConsolidatorDev';
  }

  if (process.env.REACT_APP_ENV === 'dev') {
    return 'ELDConsolidatorDev';
  }

  if (process.env.REACT_APP_ENV === 'prod') {
    return 'ELDConsolidator';
  }
})();

export const AUTH_CONFIG = {
  client_id: AUTH_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`,
  response_type: 'token id_token',
  scope: 'openid profile',
  authority: 'https://auth.loadrpm.com',
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage })
};