import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';

import * as serviceWorker from './serviceWorker';

import { RpmOidc, initUserManager } from 'rug-widgets';

import 'semantic-ui-css/semantic.min.css';

import { history, configureStore } from './shared/store/configureStore';

import CacheBuster from './CacheBuster';
import App from './App';

import * as constants from './shared/constants/constants';

import 'react-toastify/dist/ReactToastify.css';
import './index.module.scss';

initUserManager(constants.AUTH_CONFIG);

const store = configureStore();

const rootElement = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return isLatestVersion
          ? <RpmOidc store={store} history={history}
            redirectPath='/callback'
            callbackPageContent={<div>Redirecting...</div>}
            onBeforeOidcRedirect={() => {
              return true;
            }}>
            <App />
          </RpmOidc>
          : null
      }}
    </CacheBuster>
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
