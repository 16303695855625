import React from 'react';

import { Pagination, Select } from 'semantic-ui-react';

import styles from './Paginator.module.scss';

const Paginator = (props) => {
  const totalPages = Math.ceil(props.filteredCount / props.pageSize);

  const options = [
    { value: 10, text: '10' },
    { value: 20, text: '20' },
    { value: 50, text: '50' },
    { value: 100, text: '100' }
  ];

  return (
    <div className={styles.TableActions}>
      <Select
        className={styles.PageSizeSelect}
        defaultValue={props.pageSize}
        options={options}
        onChange={props.pageSizeChanged} />
      <Pagination
        pointing
        secondary
        size={props.size}
        activePage={props.page}
        totalPages={totalPages}
        className={styles.menu}
        onPageChange={props.pageChanged} />
    </div>
  );
}

export default Paginator;
