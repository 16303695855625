import React, { PureComponent } from 'react';
import cloneDeep from 'lodash.clonedeep';

import { Modal, Button, Form } from 'semantic-ui-react';

import FormElement from '../../../components/FormElement';
import ParamsDictionaryControl from './ParamsDictionaryControl/ParamsDictionaryControl';

import p44WebhookForm from './ProvidersWebhooksForms/p44WebhookForm';

import styles from './WebhookModal.module.scss';

class WebhookModal extends PureComponent {
  state = {
    providerWebhookForm: p44WebhookForm
  }

  componentDidMount() {
    if (this.props.providerWebhookBeingEdited) {
      this.initForm();
    }
  }

  initForm = () => {
    let updForm = cloneDeep(this.state.providerWebhookForm);

    for (let el in updForm) {
      updForm[el].value = this.props.providerWebhookBeingEdited[el];
    }

    this.setState({ providerWebhookForm: updForm });
  };

  handleParamsDictionaryChange = (name, formData) => {
    if (formData && formData.length) {
      let paramsDictionary = {};

      for (let el in formData) {
        paramsDictionary[`${formData[el].paramKey}`] = formData[el].paramValue;
      }

      let updForm = cloneDeep(this.state.providerWebhookForm);
      updForm[name].value = paramsDictionary;

      this.setState({ providerWebhookForm: updForm });
    }
  };

  handleChange = (e, { name, value }) => {
    let updForm = cloneDeep(this.state.providerWebhookForm);

    updForm[name].value = value;

    if (name === 'providerKey' && this.state.providerWebhookForm.providerKey.value !== updForm.providerKey.value) {
      switch (updForm.providerKey.value) {
        case 'p44':
          this.setState({ providerWebhookForm: updForm });
          break;
        default:
          break;
      }
    }

    this.setState({ providerWebhookForm: updForm });
  };

  handleSave = () => {
    let formData = {};

    for (let el in this.state.providerWebhookForm) {
      formData[el] = this.state.providerWebhookForm[el].value;
    }

    // todo: add validation

    this.props.save(formData);
  }

  render() {
    const { providerWebhookBeingEdited, show, isWorking, close } = this.props;
    const { providerWebhookForm } = this.state;

    let showNotification = providerWebhookForm.authType.value === 'APIKEY'
      && (!this.state.providerWebhookForm.authParams.value.hasOwnProperty('api_key_name')
      || this.state.providerWebhookForm.authParams.value['api_key_name'] !== 'RPM_P44_AUTH');

    let modalContent = <>
      <Form className={styles.providerWebhookForm}>
        {Object.keys(providerWebhookForm).map((el, i) => {
          if (el === 'providerKey')
            return null;

          if ((el === 'authParams' || el === 'methodParams')) {
            return <ParamsDictionaryControl key={el + i}
              header={providerWebhookForm[el].label}
              name={providerWebhookForm[el].name}
              notificationMessage={el === 'authParams' && showNotification ? `'api_key_name' parameter MUST contain 'RPM_P44_AUTH' value if using APIKEY flow.` : null}
              changed={this.handleParamsDictionaryChange}
              paramsDictionaryObject={providerWebhookForm[el].value} />
          }

          if (el === 'configName' && providerWebhookBeingEdited) {
            return <FormElement key={el + i} element={providerWebhookForm[el]} readonly />
          }

          return <FormElement key={el + i} element={providerWebhookForm[el]} changed={this.handleChange} />
        })}

      </Form>
    </>;

    return <Modal open={show} size='large' dimmer>
      <Modal.Header className={styles.modalHeader}>
        {providerWebhookBeingEdited ? 'Edit' : 'Add'} Webhook
        {providerWebhookBeingEdited ? null : <FormElement element={providerWebhookForm.providerKey} changed={this.handleChange} search />}
      </Modal.Header>
      <Modal.Content className={styles.ModalContent} >
        <div className={styles.modalFormContainer}>
          {modalContent}
        </div>
      </Modal.Content>
      {!isWorking
        ? <Modal.Actions className={styles.modalActions}>
          <Button className={styles.actionBtnReject} content='Cancel' onClick={close} />
          <Button className={styles.actionBtnBook} icon='checkmark' labelPosition='right' content="Save" onClick={this.handleSave} />
        </Modal.Actions>
        : null}
    </Modal>;
  }
}

export default WebhookModal;