import React from 'react';

import { TextMask, InputAdapter } from 'react-text-mask-hoc';

import { Form, Label } from 'semantic-ui-react';
// import { DateInput, TimeInput, DateTimeInput } from 'semantic-ui-calendar-react';

import styles from './FormElement.module.scss';

const formElement = (props) => {
  let errorLabel = <Label basic color='red' pointing='below' floating
    className={styles.errorLabel} style={props.errorLabelCustomStyle}>
    {props.element.valid ? props.element.valid.errorMessage : null}
  </Label>

  let fieldStyles = [styles.FormField];

  if (props.styles) {
    fieldStyles.push(props.styles);
  }

  let phoneInputStyles = [];
  let phoneFieldStyles = [styles.FormField];

  if (props.element.type === 'phoneInput' && props.element.touched && !props.element.valid.isValid) {
    phoneInputStyles.push(styles.phoneInputErrored);
    phoneFieldStyles.push(styles.FormFieldPhoneErrored);
  }


  switch (props.element.type) {
    case 'input':
      return <Form.Field className={styles.FormField} style={props.style}>
        <Form.Input type={props.type} min={props.min}
          id={props.element.id ? props.element.id : null}
          error={props.element.touched && !props.element.valid.isValid}
          disabled={props.disabled}
          readOnly={props.readonly}
          name={props.element.name}
          label={props.element.label}
          placeholder={props.element.placeholder}
          onChange={props.changed}
          className={props.readonly ? styles.readonlyInput : null}
          value={props.element.value ? props.element.value : ''}
          loading={props.loading ? props.loading : false}
          onBlur={props.onBlur ? props.onBlur : () => { }} />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>
    case 'labeledInput':
      return <Form.Field className={styles.FormField} style={props.style}>
        <Form.Input icon={props.icon} iconPosition={props.iconPosition} className={styles.labeledInput}
          type={props.type} min={props.min}
          id={props.element.id ? props.element.id : null}
          error={props.element.touched && !props.element.valid.isValid}
          disabled={props.disabled}
          name={props.element.name}
          label={props.element.label}
          placeholder={props.element.placeholder}
          onChange={props.changed}
          value={props.element.value ? props.element.value : ''}
          loading={props.loading ? props.loading : false}
          onBlur={props.onBlur ? props.onBlur : () => { }} />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>
    case 'phoneInput':
      return <Form.Field className={phoneFieldStyles.join(' ')} style={props.style}>
        <label style={{ color: props.element.touched && !props.element.valid.isValid ? '#9f3a38' : 'black' }}>{props.element.label}</label>
        <TextMask
          type='text'
          guide={false}
          maxLength='14'
          className={phoneInputStyles.join(' ')}
          Component={InputAdapter}
          name={props.element.name}
          placeholder={props.element.placeholder}
          mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          value={props.element.value}
          onChange={(e) => props.changed(e, { type: 'text', name: props.element.name, value: e.target.value, checked: null })} />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>
    // case 'dateInput':
    //   return <Form.Field className={styles.FormField}>
    //     <DateInput
    //       disabled={props.disabled}
    //       error={props.element.touched && !props.element.valid.isValid}
    //       name={props.element.name}
    //       label={props.element.label}
    //       placeholder={props.element.placeholder}
    //       value={props.element.value ? props.element.value : ''}
    //       iconPosition="left"
    //       onChange={props.changed} />
    //     {(props.element.touched && props.element.valid && !props.element.valid.isValid) ? errorLabel : null}
    //   </Form.Field>
    // case 'timeInput':
    //   return <Form.Field className={styles.FormField}>
    //     <TimeInput
    //       disabled={props.disabled}
    //       error={props.element.touched && !props.element.valid.isValid}
    //       name={props.element.name}
    //       label={props.element.label}
    //       placeholder={props.element.placeholder}
    //       value={props.element.value ? props.element.value : ''}
    //       iconPosition="left"
    //       onChange={props.changed} />
    //     {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
    //   </Form.Field>
    // case 'dateTimeInput':
    //   return <Form.Field className={styles.FormField}>
    //     <DateTimeInput
    //       closable
    //       disabled={props.disabled}
    //       error={props.element.touched && !props.element.valid.isValid}
    //       name={props.element.name}
    //       label={props.element.label}
    //       placeholder={props.element.placeholder}
    //       value={props.element.value ? props.element.value : ''}
    //       iconPosition="left"
    //       onChange={props.changed}
    //       dateFormat='MM/DD/Y'
    //       timeFormat='AMPM' />
    //     {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
    //   </Form.Field>
    case 'password':
      return <Form.Field className={styles.FormField}>
        <Form.Input
          type='password'
          disabled={props.disabled}
          error={props.element.touched && !props.element.valid.isValid}
          name={props.element.name}
          label={props.element.label}
          placeholder={props.element.placeholder}
          onChange={props.changed}
          value={props.element.value ? props.element.value : ''}
          loading={props.loading ? props.loading : false} />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>
    case 'checkbox':
      return <Form.Field className={styles.FormField}>
        <Form.Checkbox
          className={fieldStyles.join(' ')}
          error={props.element.touched && !props.element.valid.isValid}
          disabled={props.disabled}
          name={props.element.name}
          label={props.element.label}
          onChange={props.changed}
          checked={props.element.value ? props.element.value : false} />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>
    case 'singleSelect':
      return <Form.Field className={styles.FormField} style={props.style}>
        <Form.Dropdown selection
          search={props.search || props.allowAdditions}
          allowAdditions={props.allowAdditions}
          onAddItem={props.allowAdditions && props.onAddItem}
          onSearchChange={props.searchChanged}
          error={props.element.touched && !props.element.valid.isValid}
          style={props.inputStyle}
          disabled={props.disabled}
          name={props.element.name}
          label={props.element.label}
          placeholder={props.element.placeholder}
          options={props.element.options && props.element.options.length
            ? props.element.options
            : props.options && props.options.length
              ? props.options
              : []}
          value={props.element.value}
          onChange={props.changed}
          loading={props.loading}
          clearable={props.element.clearable}
        />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>;
    case 'multipleSelect':
      return <Form.Field className={styles.FormField}>
        <Form.Dropdown
          disabled={props.disabled}
          fluid selection multiple search={props.search}
          error={props.element.touched && !props.element.valid.isValid}
          label={props.element.label}
          name={props.element.name}
          placeholder={props.element.placeholder}
          value={props.element.value}
          options={props.element.options}
          onChange={props.changed}
          onSearchChange={props.search ? props.handleSearchChange : () => { }} />
      </Form.Field>
    case 'searchDropdown':
      return <Form.Field className={styles.FormField}>
        <Form.Dropdown clearable
          loading={props.loading}
          error={props.element.touched && !props.element.valid.isValid}
          fluid selection
          search={props.search}
          label={props.element.label}
          name={props.element.name}
          placeholder={props.element.placeholder}
          value={props.element.value}
          options={props.options}
          onChange={props.changed}
          onSearchChange={props.searchChanged} />
        {props.element.touched && !props.element.valid.isValid ? errorLabel : null}
      </Form.Field>
    default:
      return;
  }
}

export default formElement;