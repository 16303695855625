import * as constants from '../constants/validationErrorMessages';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
}

export const validate = (form) => {
  for (let el in form) {
    let validationResult;

    if (form[el].validation) {
      validationResult = checkValidity(form[el].value, form[el].validation);

      form[el].valid.isValid = validationResult.isValid;
      form[el].valid.errorMessage = validationResult.errorMessage;
      form[el].touched = true;
    } else {
      form[el].valid.isValid = true;
      form[el].valid.errorMessage = null;
      form[el].touched = true;
    }
  }

  return form;
}

export const checkValidity = (value, rules) => {
  let isValid = true;

  if (value === null || typeof (value) === 'undefined') {
    return {
      isValid: false,
      errorMessage: constants.REQUIRED_ERROR_MESSAGE
    };
  }

  if (!rules) {
    return {
      isValid: true,
      errorMessage: null
    };
  }

  if (rules.required) {
    isValid = ((value.toString().trim() !== '' || value.toString().trim() !== "") && value !== "none") && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.REQUIRED_ERROR_MESSAGE
      };
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.MIM_LENGTH_ERROR_MESSAGE} ${value.length}`
      };
  }

  if (rules.minAddressLength) {
    isValid = value.length >= rules.minAddressLength && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.ADDRESS_ERROR_MESSAGE} ${rules.minAddressLength}`
      };
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.MAX_LENGTH_ERROR_MESSAGE
      };
  }

  if (rules.maxNumberValue) {
    isValid = value <= rules.maxNumberValue && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.MAX_VALUE_ERROR_MESSAGE} (${rules.maxNumberValue})`
      };
  }

  if (rules.minNumberValue) {
    isValid = value >= rules.minNumberValue && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: `${constants.MIN_VALUE_ERROR_MESSAGE} (${rules.minNumberValue})`
      };
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.IS_NUMERIC_ERROR_MESSAGE
      };
  }

  if (rules.isDouble) {
    const pattern = /^\d{0,2}(\.\d{0,2}){0,1}$/;
    isValid = pattern.test(value) && isValid;

    return isValid
      ? {
        isValid: isValid,
        errorMessage: null
      }
      : {
        isValid: isValid,
        errorMessage: constants.DOUBLE_SEPARATOR_ERROR_MESSAGE
      };
  }
}

export const formatActionAddress = (address) => {
  const getPropertyValueOrEmptyString = (propVal) => {
    return propVal ? propVal : '';
  }

  return `${getPropertyValueOrEmptyString(address.street).toLowerCase()} `
    + `${getPropertyValueOrEmptyString(address.city).toLowerCase()},`
    + ` ${getPropertyValueOrEmptyString(address.zip)}, ${getPropertyValueOrEmptyString(address.state)}, ${getPropertyValueOrEmptyString(address.country)}`;
}

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // const intlCode = (match[1] ? '+1 ' : '');
    return ['+1 ', '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null;
}