import axios from '../../../../axios/axios-api';

import * as actionTypes from '../constants/actionTypes';

const fetchWebhooksStart = () => { return { type: actionTypes.FETCH_WEBHOOKS_START }; }
const fetchWebhooksSuccess = (webhooks) => {
  return {
    type: actionTypes.FETCH_WEBHOOKS_SUCCESS,
    webhooks: webhooks
  };
}
const fetchWebhooksError = () => { return { type: actionTypes.FETCH_WEBHOOKS_ERROR }; }

export const fetchWebhooks = () => {
  return dispatch => {
    dispatch(fetchWebhooksStart());

    axios.get('/Webhooks')
      .then(res => dispatch(fetchWebhooksSuccess(res.data)))
      .catch(err => dispatch(fetchWebhooksError()));
  };
}

const saveWebhookStart = () => { return { type: actionTypes.SAVE_WEBHOOKS_START }; }
const saveWebhookSuccess = () => { return { type: actionTypes.SAVE_WEBHOOKS_SUCCESS }; }
const saveWebhookError = () => { return { type: actionTypes.SAVE_WEBHOOKS_ERROR }; }

export const saveWebhook = (obj) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(saveWebhookStart());

      axios.post('/Webhooks', obj)
        .then((response) => {
          dispatch(saveWebhookSuccess());

          resolve(response);
        })
        .catch((error) => {
          dispatch(saveWebhookError());

          reject(error);
        });
    });
  }
}

const deleteWebhookStart = () => { return { type: actionTypes.DELETE_WEBHOOKS_START }; }
const deleteWebhookSuccess = () => { return { type: actionTypes.DELETE_WEBHOOKS_SUCCESS }; }
const deleteWebhookError = () => { return { type: actionTypes.DELETE_WEBHOOKS_ERROR }; }

export const deleteWebhook = (providerKey, id) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(deleteWebhookStart());

      axios.delete(`/Webhooks/${providerKey}/${id}`)
        .then((response) => {
          dispatch(deleteWebhookSuccess());
          
          resolve(response);
        })
        .catch((error) => {
          dispatch(deleteWebhookError());
          
          reject(error);
        });
    });
  };
}