import React, { PureComponent } from 'react';
import cloneDeep from 'lodash.clonedeep';

import FormElement from '../../../../components/FormElement';

import paramsDictionaryForm from './paramsDictionaryForm';

import styles from './ParamsDictionaryControl.module.scss';

class ParamsDictionaryControlItem extends PureComponent {
  state = {
    form: cloneDeep(paramsDictionaryForm)
  };

  componentDidMount() {
    if (this.props.item)
      this.initForm();
  }

  initForm = () => {
    let updForm = cloneDeep(this.state.form);

    for (let el in updForm) {
      updForm[el].value = this.props.item[el];
    }

    this.setState({ form: updForm });
  }

  fetchData = (updForm) => {
    let formData = {};

    for (let el in updForm) {
      formData[el] = updForm[el].value;
    }

    formData.id = this.props.item.id;

    this.props.changed(formData);
  }

  handleChange = (e, { name, value }) => {
    let updForm = cloneDeep(this.state.form);

    updForm[name].value = value;

    this.setState({ form: updForm }, () => this.fetchData(updForm));
  }

  render() {
    const { form } = this.state;

    return <div className={styles.paramsDictItemContainer} >
      {Object.keys(form).map(el => {
        if (el === 'providerKey')
          return null;

        return <FormElement key={el} element={form[el]}
          changed={(e, { name, value }) => this.handleChange(e, { name, value })}
          style={{ width: '48%', marginBottom: '0px' }} />
      })}
    </div>;
  }
}

export default ParamsDictionaryControlItem;