import * as actionTypes from '../constants/sharedActionTypes';

import { updateObject } from '../../utils/utility';

const initialState = {
  loading: false,
  apiVersion: null
}

const getApiVersionStart = (state, action) => updateObject(state, { loading: true });
const getApiVersionSuccess = (state, action) => updateObject(state, { loading: false, apiVersion: action.apiVersion });
const getApiVersionError = (state, action) => updateObject(state, { loading: false });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_API_VERSION_START: return getApiVersionStart(state, action);
    case actionTypes.GET_API_VERSION_SUCCESS: return getApiVersionSuccess(state, action);
    case actionTypes.GET_API_VERSION_ERROR: return getApiVersionError(state, action);

    default: return state;
  }
}

export default reducer;
