import React, { useState } from 'react';

import { RugAppsWidget } from 'rug-widgets';

import ToolbarMenu from '../../components/Navigation/Toolbar/ToolbarMenu';

import * as constants from '../../shared/constants/constants';

import styles from './Layout.module.scss';

const layout = ({
  children,
  user,
  apiVersion
}) => {
  const [activeItem, setActiveItem] = useState(null);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  return <>
    <ToolbarMenu
      apiVersion={apiVersion}
      activeItemChanged={handleItemClick}
      activeItem={activeItem} />
    <main className={styles.Content}>
      {children}
      {user ? <RugAppsWidget
        accessToken={user.access_token}
        spellbarConfigEndpointUrl={constants.SPELLBAR_CONFIG_ENDPOINT_URL} /> : null}
    </main>
  </>;
}

export default layout;