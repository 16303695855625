import React from 'react';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { Confirm } from 'semantic-ui-react';

import GridBase from '../../components/GridBase/GridBase';
import ResourceViewer from '../../components/ResourceViewer/ResourceViewer';

import WebhookModal from './WebhookModal/WebhookModal';

import * as actions from '../../shared/store/actions';

class Webhooks extends GridBase {
  componentDidMount() {
    this.setState({
      sort: {
        sortField: 'name',
        dir: 'asc'
      },
      showDeleteConfirmation: false,
      isWorking: false,
      showModal: false,
      selectedWebhook: null,
      webhooks: [],
      providerWebhookBeingEdited: null
    }, () => this.loadData());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.webhooks !== this.props.webhooks) {
      if (this.props.webhooks.data) {
        this.setState({ webhooks: this.props.webhooks });
      }
    }
  }

  loadData() {
    const gridState = {
      page: this.state.page,
      pageSize: this.state.pageSize,
      sort: this.state.sort,
      filter: this.state.filter
    };

    this.props.fetchWebhooks(gridState);
  }

  handleShowConfirmationDialog = (e, webhook) => this.setState({ selectedWebhook: webhook, showDeleteConfirmation: true });

  handleDeleteCancel = () => this.setState({ showDeleteConfirmation: false, selectedWebhook: null });

  handleDeleteConfirm = () => {
    this.props.deleteWebhook(this.state.selectedWebhook.providerKey, this.state.selectedWebhook.providerInternalId)
      .then(() => {
        this.setState({ isWorking: false, showDeleteConfirmation: false });

        this.loadData();
      })
      .catch(() => {
        this.setState({ isWorking: false, showDeleteConfirmation: false });

        this.loadData();
      });
  }

  handleSave = (formData) => {
    let updFormData = cloneDeep(formData);

    updFormData.authParams = formData.authParams !== '' ? JSON.stringify(formData.authParams) : '';
    updFormData.methodParams = formData.methodParams !== '' ? JSON.stringify(formData.methodParams) : '';
    updFormData.providerInternalId = this.state.providerWebhookBeingEdited ? this.state.providerWebhookBeingEdited.providerInternalId : '';

    this.props.saveWebhook(updFormData)
      .then(() => {
        this.setState({ isWorking: false, showModal: false });

        this.loadData();
      })
      .catch(() => {
        this.setState({ isWorking: false, showModal: false });

        this.loadData();
      });
  }

  handleAddNewProvider = () => this.setState({ showModal: true });

  handleEditProvider = (item) => {
    let updItem = cloneDeep(item);

    if (updItem.authParams) {
      if (updItem.authParams !== '{}') {
        updItem.authParams = JSON.parse(item.authParams);
      } else {
        updItem.authParams = '';
      }
    }

    if (updItem.methodParams) {
      if (updItem.methodParams !== '{}') {
        updItem.methodParams = JSON.parse(item.methodParams);
      } else {
        updItem.methodParams = '';
      }
    }

    this.setState({ showModal: true, providerWebhookBeingEdited: updItem });
  }

  handleCancelAdd = () => this.setState({ showModal: false, providerWebhookBeingEdited: null });

  render() {
    let columns = [
      { header: 'Provider Name', id: 'providerName', dataType: 'string', isHidden: false, isReadOnly: true, width: '4' },
      { header: 'Webhook Url', id: 'webhookUrl', search: false, dataType: 'string' }
    ];

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <ResourceViewer fixed
          header='Webhooks'
          keyColumnName='fakeId'
          idColumnName='fakeId'
          editMode={true}
          actions={false}
          refreshTable={() => this.loadData()}
          createResourceLink=''
          columns={columns}
          sortField={this.state.sort.sortField}
          dir={this.state.sort.dir}
          sort={this.handleSort}
          filterGrid={this.handleFilter}
          filter={this.state.filter}
          page={this.state.page}
          pageSize={this.state.pageSize}
          pageSizeChanged={this.handlePageSizeChange}
          pageChanged={this.handlePageChange}
          loading={this.props.webhooksLoading}
          data={this.state.webhooks || []}
          handleEditRow={this.handleEditProvider}
          handleAddNewRow={this.handleAddNewProvider}
          handleCancelAdd={this.handleCancelAdd}
          handleRemoveRow={this.handleShowConfirmationDialog} />

        <Confirm size="small"
          content='Are you sure you want to delete this webhook?'
          open={this.state.showDeleteConfirmation}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm} />

        {this.state.showModal
          ? <WebhookModal
            show={this.state.showModal}
            isWorking={this.state.isWorking}
            close={this.handleCancelAdd}
            save={this.handleSave}
            providerWebhookBeingEdited={this.state.providerWebhookBeingEdited} />
          : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    webhooksLoading: state.webhooks.webhooksLoading,
    webhooks: state.webhooks.webhooks
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWebhooks: (gridState) => dispatch(actions.fetchWebhooks(gridState)),
    saveWebhook: (webhook) => dispatch(actions.saveWebhook(webhook)),
    deleteWebhook: (providerKey, id) => dispatch(actions.deleteWebhook(providerKey, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Webhooks);