import { providersList } from '../../../../shared/constants/listConstants';

const p44WebhookForm = {
  providerKey: {
    type: 'singleSelect',
    name: 'providerKey',
    options: providersList,
    label: '',
    value: providersList[0].value,
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  },
  authType: {
    type: 'singleSelect',
    name: 'authType',
    label: 'Authorization Type',
    options: [
      { key: 'APIKEY', text: 'APIKEY', value: 'APIKEY' },
      { key: 'BASIC', text: 'BASIC', value: 'BASIC' }
    ],
    value: '',
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  },
  authParams: {
    type: 'input',
    name: 'authParams',
    label: 'Authorization  Parameters',
    value: '',
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  },
  configName: {
    type: 'input',
    name: 'configName',
    label: 'Config Name',
    value: '',
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  },
  method: {
    type: 'singleSelect',
    name: 'method',
    label: 'Method',
    options: [
      { key: 'GET', text: 'GET', value: 'GET' },
      { key: 'POST', text: 'POST', value: 'POST' },
      { key: 'PUT', text: 'PUT', value: 'PUT' }
    ],
    value: '',
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  },
  methodParams: {
    type: 'input',
    name: 'methodParams',
    label: 'Method Parameters',
    value: '',
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  },
  webhookUrl: {
    type: 'input',
    name: 'webhookUrl',
    label: 'Webhook URL',
    value: '',
    valid: {
      isValid: true,
      errorMessage: null
    },
    validation: null,
    touched: false
  }
}

export default p44WebhookForm;