export const FETCH_WEBHOOKS_START = 'FETCH_WEBHOOKS_START';
export const FETCH_WEBHOOKS_SUCCESS = 'FETCH_WEBHOOKS_SUCCESS';
export const FETCH_WEBHOOKS_ERROR = 'FETCH_WEBHOOKS_ERROR';

export const SAVE_WEBHOOKS_START = 'SAVE_WEBHOOKS_START';
export const SAVE_WEBHOOKS_SUCCESS = 'SAVE_WEBHOOKS_SUCCESS';
export const SAVE_WEBHOOKS_ERROR = 'SAVE_WEBHOOKS_ERROR';

export const DELETE_WEBHOOKS_START = 'DELETE_WEBHOOKS_START';
export const DELETE_WEBHOOKS_SUCCESS = 'DELETE_WEBHOOKS_SUCCESS';
export const DELETE_WEBHOOKS_ERROR = 'DELETE_WEBHOOKS_ERROR';