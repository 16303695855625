const paramsDictionaryForm = {
  paramKey: {
    type: 'input',
    name: 'paramKey',
    label: 'Parameter Key',
    value: '',
    valid: {
      isValid: false,
      errorMessage: null
    },
    validation: { required: true },
    touched: false
  },
  paramValue: {
    type: 'input',
    name: 'paramValue',
    label: 'Parameter Value',
    value: '',
    valid: {
      isValid: false,
      errorMessage: null
    },
    validation: { required: true },
    touched: false
  }
};

export default paramsDictionaryForm;