import React, { PureComponent } from 'react';
import cloneDeep from 'lodash.clonedeep';

import { Header, Icon, Divider } from 'semantic-ui-react';

import ParamsDictionaryControlItem from './ParamsDictionaryControlItem';

import styles from './ParamsDictionaryControl.module.scss';

class ParamsDictionaryControl extends PureComponent {
  state = {
    paramsDictionary: [],
    isLoading: false
  };

  componentDidMount() {
    this.setState({ isLoading: true }, () => this.initParamsDictionaryItem());
  }

  addNewElement = () => {
    if (!this.state.paramsDictionary.some(el => el.paramKey === '' || el.paramValue === '')) {
      this.initParamsDictionaryItem(true);
    }
  };

  initParamsDictionaryItem = (increment) => {
    if (this.props.paramsDictionaryObject !== null && this.props.paramsDictionaryObject !== '' && !increment) {
      let paramsDictionaryItems = [];

      let index = 1;
      for (let [key, value] of Object.entries(this.props.paramsDictionaryObject)) {
        paramsDictionaryItems.push({
          id: `${index++}.`,
          paramKey: key,
          paramValue: value
        });
      }

      this.setState({ paramsDictionary: paramsDictionaryItems, isLoading: false });
    } else {
      let paramsDictionaryItem = {
        id: increment ? `${this.state.paramsDictionary.length + 1}.` : '1.',
        paramKey: '',
        paramValue: ''
      }

      let updParamsDictionary = cloneDeep(this.state.paramsDictionary);
      updParamsDictionary.push(paramsDictionaryItem);

      this.setState({ paramsDictionary: updParamsDictionary, isLoading: false });
    }
  };

  fetchParamsDictionary = (updParamsDictionary) => this.props.changed(this.props.name, updParamsDictionary);

  handleChangeItem = (item) => {
    let updParamsDictionary = cloneDeep(this.state.paramsDictionary);
    let existingParamsDictionary = updParamsDictionary.find(el => el.id === item.id);

    if (existingParamsDictionary) {
      let index = updParamsDictionary.indexOf(existingParamsDictionary);

      updParamsDictionary[index] = item;
    }

    this.setState({ paramsDictionary: updParamsDictionary },
      () => this.fetchParamsDictionary(updParamsDictionary));
  }

  handleRemoveItem = (item) => {
    let updParamsDictionary = cloneDeep(this.state.paramsDictionary);

    let index = updParamsDictionary.findIndex(r => r.id === item.id);

    if (index > -1)
      updParamsDictionary.splice(index, 1);

    this.setState({ paramsDictionary: updParamsDictionary },
      () => this.fetchParamsDictionary(updParamsDictionary));
  }

  render() {
    const { isLoading, paramsDictionary } = this.state;
    const { header } = this.props;

    return isLoading ? null
      : <div>
        <Header as='h4' dividing style={{ display: 'flex' }}>
          {header}
          {!paramsDictionary.some(el => el.paramKey === '' || el.paramValue === '')
            ? <Icon name='add circle' color='grey'
              className={styles.addIcon}
              title='Add new item'
              onClick={() => this.addNewElement()} />
            : null}
        </Header>

        <div className={styles.paramsDictContainer}>
          {this.props.notificationMessage ? <p style={{ color: 'red' }}>{this.props.notificationMessage}</p> : null}
          {paramsDictionary && paramsDictionary.length
            ? paramsDictionary.map(item => {
              return <div key={item.id} className={styles.paramsDictItem}>
                {item.id}
                <ParamsDictionaryControlItem item={item} changed={this.handleChangeItem} />
                {/* triggerRateItemsValidation={this.props.triggerRateItemsValidation} */}
                {paramsDictionary.length > 1
                  ? <Icon name='trash' color='grey' onClick={() => this.handleRemoveItem(item)} style={{ cursor: 'pointer', marginLeft: '5px' }} />
                  : <Icon name='trash' color='grey' style={{ visibility: 'hidden', marginLeft: '5px' }} />}
              </div>
            })
            : null}
        </div>
        <Divider />
      </div>;
  }
}

export default ParamsDictionaryControl;