import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userManager } from 'rug-widgets';

import { Dropdown, Icon } from 'semantic-ui-react';

import styles from '../Toolbar/ToolbarMenu.module.scss';

class UserWidget extends Component {
  handleLogout = () => {
    userManager.signoutRedirect()
      .catch(function (error) {
        console.error('error while signing out user', error);
      });
  }

  render() {
    let widget = null;

    widget = (
      <>
        {this.props.user && (<>
          <Icon name='user circle' size='big' className={styles.userIcon} />
          <Dropdown className={styles.userDropdown} item text={this.props.user ? this.props.user.profile.name : ''}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={this.handleLogout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>)}
      </>
    );

    return widget;
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
    isLoadingUser: state.oidc.isLoadingUser
  }
}

export default connect(mapStateToProps, null)(UserWidget);